import React, { useContext } from "react"
import ReactTable from "react-table-v6"

import { StyledSelect } from "../select"
import { ButtonNoLink } from "../buttons"
import Pagination from "./pagination"
import "react-table-v6/react-table.css"
import "./custom.css"
import Styles from "./esta.module.css"
import HrtStyles from "./hrt.module.css"
import ReactTooltip from "react-tooltip"
import { HRTController, HRTSearchContext } from "./hrtcontrol"

const HRTCode = ({ row, original, value }) => {
	if (!original.pest_specie.code_note) {
		return <p>{value}</p>
	} else {
		return (
			<div className="flex justify-between">
				<p>{value}</p>
				<div
					className="tooltip"
					data-tip={original.pest_specie.code_note}
					data-place="bottom"
					data-multiline={true}
					data-html={true}
					data-delay-hide={750}
					data-background-color="#fff"
					data-text-color="#404040"
					data-class="tooltip_content"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="16"
						viewBox="0 0 512 512"
					>
						<path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464zM256 304c13.25 0 24-10.75 24-24v-128C280 138.8 269.3 128 256 128S232 138.8 232 152v128C232 293.3 242.8 304 256 304zM256 337.1c-17.36 0-31.44 14.08-31.44 31.44C224.6 385.9 238.6 400 256 400s31.44-14.08 31.44-31.44C287.4 351.2 273.4 337.1 256 337.1z" />
					</svg>
				</div>
				<ReactTooltip clickable={true} />
			</div>
		)
	}
}

const HRTLevel = ({ row, original, value }) => {
	if (!original.level_note) {
		return <p>{value}</p>
	} else {
		return (
			<div className="flex justify-between">
				<p>{value}</p>
				<div
					className="tooltip"
					data-tip={original.level_note}
					data-place="bottom"
					data-multiline={true}
					data-html={true}
					data-delay-hide={750}
					data-background-color="#fff"
					data-text-color="#404040"
					data-class="tooltip_content"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="16"
						viewBox="0 0 512 512"
					>
						<path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464zM256 304c13.25 0 24-10.75 24-24v-128C280 138.8 269.3 128 256 128S232 138.8 232 152v128C232 293.3 242.8 304 256 304zM256 337.1c-17.36 0-31.44 14.08-31.44 31.44C224.6 385.9 238.6 400 256 400s31.44-14.08 31.44-31.44C287.4 351.2 273.4 337.1 256 337.1z" />
					</svg>
				</div>
				<ReactTooltip clickable={true} />
			</div>
		)
	}
}

const StupidSelect = ({ choices, value, ...rest }) => {
	return (
		<StyledSelect
			choices={choices}
			value={value && choices.find((o) => o.value === value)}
			{...rest}
		/>
	)
}

const HRTFilters = () => {
	const ctx = useContext(HRTSearchContext)
	const { options, query, updateQuery, loading, search } = ctx
	return (
		<div className={Styles.filtersContainer}>
			{!loading && (
				<>
					<div className={Styles.topContainer}>
						<div className={HrtStyles.filterLabel}>
							<span>Plant:</span>
						</div>
						<div className={Styles.filter}>
							<StupidSelect
								placeholder="Species"
								choices={options.crops
									.map((c) => ({
										label: c.scientificName,
										value: c.id,
									}))
									.sort((a, b) => (a.label > b.label ? 1 : -1))}
								value={query?.crop}
								onChange={(option) => {
									updateQuery({
										key: "crop",
										value: option?.value ?? null,
									})
								}}
								isSearchable={true}
								isClearable={true}
							/>
						</div>
						<div className={Styles.filter}>
							<StupidSelect
								placeholder="Crop"
								choices={options.crops
									.map((c) => ({
										label: c.commonName,
										value: c.id,
									}))
									.sort((a, b) => (a.label > b.label ? 1 : -1))}
								value={query?.crop}
								onChange={(option) =>
									updateQuery({
										key: "crop",
										value: option?.value ?? null,
									})
								}
								isSearchable={true}
								isClearable={true}
							/>
						</div>
						<div className={Styles.filter}>
							<input
								type="text"
								placeholder="Example Variety"
								value={query?.exampleSearch}
								onChange={(e) => {
									updateQuery({ key: "exampleSearch", value: e.target.value })
								}}
							/>
						</div>
					</div>

					<div className={Styles.topContainer}>
						<div className={HrtStyles.filterLabel}>
							<span>Disease:</span>
						</div>
						<div className={Styles.filter}>
							<StupidSelect
								placeholder="Type"
								choices={options.pestTypes
									.map((t) => ({
										value: t.id,
										label: t.name,
									}))
									.sort((a, b) => (a.label > b.label ? 1 : -1))}
								value={query?.pestType}
								onChange={(o) =>
									updateQuery({
										key: "pestType",
										value: o?.map((i) => i.value) ?? [],
									})
								}
								isSearchable={false}
								isClearable={true}
								isMulti
							/>
						</div>
						<div className={Styles.filter}>
							<StupidSelect
								placeholder="English Common name"
								choices={options.pestsCommonNames
									.map((n) => ({
										value: n.name,
										label: n.name,
									}))
									.sort((a, b) => (a.label > b.label ? 1 : -1))}
								value={query?.pestCommonName}
								onChange={(o) => {
									updateQuery({
										key: "pestCommonName",
										value: o?.value ?? null,
									})
								}}
								isSearchable={true}
								isClearable={true}
							/>
						</div>

						<div className={Styles.filter}>
							<StupidSelect
								placeholder="Resistance level"
								choices={options?.resistanceLvls.map((l) => ({
									value: l.id,
									label: l.label,
								}))}
								value={query?.resistanceLvl}
								onChange={(o) => {
									updateQuery({
										key: "resistanceLvl",
										value: o?.map((i) => i.value) ?? [],
									})
								}}
								isSearchable={false}
								isClearable={true}
								isMulti
							/>
						</div>
					</div>

					<div className={Styles.topContainer}>
						<div className={HrtStyles.filterLabel}>
							<span>Pathogen:</span>
						</div>

						<div className={Styles.filter}>
							<StupidSelect
								placeholder="Scientific Name"
								choices={options.pests
									.map((p) => ({ value: p.id, label: p.name }))
									.sort((a, b) => (a.label > b.label ? 1 : -1))}
								value={query?.pestSpecie}
								onChange={(o) => {
									updateQuery({ key: "pestSpecie", value: o?.value ?? "" })
								}}
								isSearchable={true}
								isClearable={true}
							/>
						</div>

						<div className={Styles.filter}>
							<StyledSelect
								placeholder="Pest code: Races/Strains"
								choices={options.pestsCodes.map((c) => ({
									value: c.code,
									label: c.code,
								}))}
								value={query?.pestCode}
								onChange={(o) => {
									updateQuery({ key: "pestCode", value: o })
								}}
								isSearchable={true}
								isClearable={true}
							/>
						</div>

						<div
							className={`${Styles.filterButton}`}
							style={{ marginLeft: "auto" }}
						>
							<ButtonNoLink onClick={() => search({ page: 0 })}>
								Filter
							</ButtonNoLink>
						</div>
					</div>
				</>
			)}
		</div>
	)
}
const HRTTable = (props) => {
	const ctx = useContext(HRTSearchContext)
	const { loading, data, search } = ctx

	const columns = [
		{
			Header: "Species",
			id: "crop__name",
			accessor: (d) => d.crop.name,
		},
		{
			Header: "Crop",
			id: "crop__crop_name",
			accessor: (d) => d.crop.crop_name,
		},

		{
			Header: "Scientific Name",
			id: "pest_specie__name",
			accessor: (d) => d.pest_specie.name,
			minWidth: 200,
		},
		{
			Header: "English common name",
			id: "pest_specie__common_names__name",
			accessor: (d) =>
				d.pest_specie.common_names.length > 0
					? d.pest_specie.common_names.map((c) => c.name).join("\n")
					: "",
			minWidth: 200,
		},
		{
			Header: "Pest code: Races/Strains",
			id: "pest_specie__code",
			accessor: (d) => {
				let res = d.pest_specie.code
				if (d.pest_strain) {
					res = `${res}:${d.pest_strain}`
				}
				return res
			},
			width: 150,
			sortable: true,
			Cell: (props) => <HRTCode {...props} />,
		},
		{
			Header: "Type",
			id: "pest_specie__pestType__name",
			accessor: (d) => d.pest_specie.pestType.name,
			width: 100,
			sortable: true,
		},
		{
			Header: "Level",
			id: "level",
			accessor: (d) => d.level_display,
			width: 75,
			sortable: true,
			Cell: (props) => <HRTLevel {...props} />,
		},
		{
			Header: "Example variety",
			id: "examples",
			sortable: false,
			accessor: (d) => d.examples,
			width: 250,
		},
	]
	return (
		<>
			{loading || (data && data.data.length > 0) ? (
				<div>
					{data.count > 0 && (
						<div className={Styles.searchCount}>
							<p>Found {data.count} items</p>
						</div>
					)}
					<div className="table-container">
						<ReactTable
							data={data.data}
							columns={columns}
							pages={data.pages}
							loading={loading}
							className="main-table hrt-table"
							resizable={false}
							minRows={0}
							defaultPageSize={data.pageSize}
							onFetchData={search}
							showPagination={true}
							PaginationComponent={(props) => (
								<Pagination
									{...props}
									showTotalCount={true}
									totalCount={data.count}
								/>
							)}
							getTdProps={(state, rowInfo, column, instance) => {
								if (
									column.id === "level" &&
									["COMM", "REV"].includes(rowInfo.original.level)
								) {
									return {
										className: "expanded",

										style: {
											flex: 1,
											flexGrow: 325,
											minWidth: 325,
										},
									}
								}
								if (
									column.id === "examples" &&
									["COMM", "REV"].includes(rowInfo.original.level)
								) {
									return {
										className: "collapsed hidden",
										style: {
											padding: 0,
											flex: 0,
											flexGrow: 0,
											minWidth: 0,
											maxWidth: 0,
										},
									}
								}
								return {}
							}}
							manual
							defaultSorted={[
								{
									id: "crop__name",
									desc: false,
								},
							]}
							NoDataComponent={() => null}
						/>
					</div>
				</div>
			) : (
				<h1 className="tableNoResults">No Results</h1>
			)}
		</>
	)
}

export const Hrt = (props) => (
	<HRTController>
		<HRTFilters />
		<HRTTable />
	</HRTController>
)
